//// ----------------------------------------------------------------------
///
/// typography variables
///
/// @group configuration
/// @module sass/configuration/foundation/typography
///
//// ----------------------------------------------------------------------

$es-font-primary: Inter, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif !default;
$es-font-secondary: Inter, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif !default;

$es-font-headings: $es-font-primary;
$es-font-data: $es-font-primary;

///@type px
$es-font-size-default: 13px !default;
///@type px
$es-line-height: 20px !default;

///@type px
$es-small-font-size: 11px !default;
///@type px
$es-small-line-height: 16px !default;

///headings h1/h2/h3/h4
$es-font-weight-headings: 500;
$es-font-weight-h1: 500;
$es-font-weight-h2: 500;
$es-font-weight-h3: 500;
$es-font-weight-h4: 600;
///@type px
$es-h1-font-size: 36px;
///@type px
$es-h1-line-height: $es-h1-font-size * 1.15;
///@type px
$es-h2-font-size: 26px;
///@type px
$es-h2-line-height: $es-h2-font-size * 1.25;
///@type px
$es-h3-font-size: 20px;
///@type px
$es-h3-line-height: $es-h3-font-size * 1.25;
///@type px
$es-h4-font-size: 16px;
///@type px
$es-h4-line-height: $es-h4-font-size * 1.25;