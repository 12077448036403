//// ----------------------------------------------------------------------
///
/// variables for nav item (Component of sidebar)
///
/// @group configuration
/// @module sass/configuration/organisms/sidebar
///
//// -----------------------------------------------------------------------

$es-nav-item-transition-duration: .33s;

// Colors
$es-nav-item-color: es-color('clario-mono', 'gray-11');
$es-nav-item-color-active: es-color('clario-mono', 'gray-12');
$es-nav-item-color-hover: es-color('clario-mono', 'gray-12');
$es-nav-item-background-color: es-color('clario-mono', 'white');
$es-nav-item-background-color-hover: es-color('clario-mono', 'gray-3');
$es-nav-item-background-color-active: es-color('clario-mono', 'gray-2');
$es-nav-item-border-color: es-color('clario-mono', 'white');
$es-nav-item-icon-color-hover: es-color("clario-mono", "gray-12");

$es-sidebar-subnav-border-color: $es-nav-item-border-color;
$es-sidebar-subnav-hover-background-color: $es-nav-item-background-color-hover;

$es-nav-item-icon-size: 15px;
$es-nav-item-icon-size-help: 2.5 * $es-pixel-unit;
$es-nav-item-chevron-size: 10px;
$es-nav-item-chevron-size-small: 8px;
$es-nav-item-chevron-right: $es-space-large;
$es-nav-item-height: $es-pixel-unit * 5;
