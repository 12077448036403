//// ----------------------------------------------------------------------
///
/// general variables
///
/// @group configuration
/// @module sass/configuration/general
///
//// ----------------------------------------------------------------------

/// basepath
$es-path-to-assets: '../' !default;

/// Default units (for dependent calculations)
//// ----------------------------------------------------------------------
$es-base-unit: 10px;        // this unit represents the number of pixels per 1.0rem
$es-pixel-unit: 10px;
//$es-grid-unit: 8px;         // baseline grid unit (number of pixels)

// Z-indices
$es-z-index-topmost: 1000;
$es-z-index-overlay: $es-z-index-topmost - 1;

/// Visual styles
//------------------------------------------------------------------------------

// Box shadows
$es-shadow: 0px 2px 5px 0px $es-shadow-color;

//separator -> only used in grid, move it there?
$es-separator-border: 1px solid $es-border-light-color;