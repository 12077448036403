$tabletSize: 130px;
$errorSize: 48px;

.emulation-error-icon {
  margin-top: 60px;
  position: relative;
  text-align: center;
}
.emulation-error-icon .mat-icon {
  color: $vv-purple;
}
.emulation-error-icon__tablet {
  font-size: $tabletSize !important;
  width: $tabletSize !important;
  height: $tabletSize !important;
}

.emulation-error-icon__error {
  font-size: $errorSize !important;
  width: $errorSize !important;
  height: $errorSize !important;
  position: absolute;
  top: calc($tabletSize/2) - calc($errorSize/2);
  left: 50%;
  transform: translateX(-50%);
  color: #cd3d51;
}
