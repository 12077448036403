// Removed for now, because configuration is also in the webfonts sass file

/*@font-face {
    font-family: 'ert-icons';
    src: url("../fonts/ert-icons/ert-icons.eot");
    src: url("../fonts/ert-icons/ert-icons.?#iefix") format("embedded-opentype"), url("../fonts/ert-icons/ert-icons.woff") format("woff"), url("../fonts/ert-icons/ert-icons.ttf") format("truetype"), url("../fonts/ert-icons/ert-icons.svg#ert-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}
*/

$es-icons-2d: (
    address: '\E00A',
	agenda: '\E010',
	alert-danger: '\E01A',
	alert-info: '\E020',
	alert-success: '\E02A',
	alert-warning: '\E030',
	all-possible-answers: '\E03A',
	android-add-circle: '\E040',
	android-arrow-dropdown: '\E04A',
	application-mode: '\E050',
	area-chart: '\E05A',
	bar-chart-small: '\E060',
	bar-chart: '\E06A',
	bars: '\E070',
	base: '\E07A',
	bell-circle: '\E080',
	bell: '\E08A',
	breadcrumb-country: '\E090',
	breadcrumb-event: '\E09A',
	breadcrumb-location: '\E0A0',
	breadcrumb-study: '\E0AA',
	breadcrumb-test-result: '\E0B0',
	breadcrumb-user: '\E0BA',
	breadcrumb-visit: '\E0C0',
	calendar-check: '\E0CA',
	calendar-five-months: '\E0D0',
	calendar-multiple-months: '\E0DA',
	calendar-normal: '\E0DB',
	calendar-normal-overdue: '\E0E0',
	calendar-one-month: '\E0EA',
	calendar-randomization-overdue: '\E0F0',
	calendar-randomization: '\E0FA',
	calendar-termination: '\E100',
	calendar-three-months: '\E10A',
	calendar-unscheduled: '\E110',
	calendar: '\E11A',
	chevron-down: '\E120',
	chevron-left: '\E12A',
	chevron-right: '\E130',
	to-left: '\E135',
	to-right: '\E136',
	chevron-up: '\E13A',
	circle-one: '\E140',
	circle: '\E14A',
	client-uat: '\E150',
	clinical-data: '\E15A',
	close: '\E160',
	collapse: '\E16A',
	complete: '\E170',
	content-calendar: '\E17A',
	content-time: '\E180',
	content-warning: '\E18A',
	dashboard: '\E190',
	data-export: '\E19A',
	data-management: '\E1A0',
	download: '\E1AA',
	email: '\E1B0',
	reply: '\E1B1',
	expand: '\E1BA',
	export: '\E1C0',
	fax: '\E1CA',
	qrcode: '\E1CB',
	function-approve-or-reject-removal: '\E1D0',
	function-approve-removal: '\E1DA',
	function-delete: '\E1E0',
	function-edit: '\E1EA',
	function-reject-removal: '\E1F0',
	function-scoring-results: '\E1FA',
	globe: '\E200',
	graph: '\E20A',
	grid: '\E210',
	group-view: '\E21A',
	help: '\E220',
	home: '\E22A',
	incomplete: '\E230',
	info-circle: '\E23A',
	info: '\E240',
	landline-phone: '\E24A',
	line-chart: '\E250',
	line-graph-dotted: '\E25A',
	line-graph: '\E260',
	link-broken: '\E26A',
	link: '\E270',
	magnifier: '\E27A',
	missed: '\E280',
	mobile-phone: '\E28A',
	more: '\E290',
	ellipsis: '\E291',
	new-site-qualification: '\E29A',
	next: '\E2A0',
	no-data-shadow: '\E2AA',
	notification-check: '\E2B0',
	notification-failure: '\E2BA',
	notification-information: '\E2C0',
	notification-warning: '\E2CA',
	one-answers: '\E2D0',
	options: '\E2DA',
	paperclip: '\E2E0',
	pin: '\E2EA',
	portal-administration: '\E2F0',
	previous: '\E2FA',
	print: '\E300',
	file-code: '\E301',
	file-excel: '\E302',
	file-image: '\E303',
	file-pdf: '\E304',
	file-powerpoint: '\E305',
	file-text: '\E306',
	file-word: '\E307',
	file-alt: '\E308',
	create-trans: '\E309',
	proficiency-mode: '\E30A',
	questionmark: '\E31A',
	reference-material: '\E320',
	reports: '\E32A',
	report-confirm: '\E32B',
	row-checkmark: '\E330',
	row-close: '\E33A',
	row-triangle: '\E340',
	sample-mode: '\E34A',
	site-qualification-form: '\E350',
	spirometry-amount-checkmark: '\E35A',
	spirometry-amount-dashedline: '\E360',
	spirometry-first-bestresult: '\E36A',
	spirometry-running-simple: '\E370',
	spirometry-running: '\E37A',
	spirometry-second-bestresult: '\E380',
	spirometry-value: '\E38A',
	spirometry-view-mode: '\E390',
	study-name: '\E39A',
	supply-box: '\E3A0',
	supply-ordering: '\E3AA',
	tile-view: '\E3B0',
	training-certificates: '\E3BA',
	training-mode: '\E3C0',
	user-management-study-permission: '\E3CA',
	user-name: '\E3D0',
	world: '\E3DA',
	remote-viewer: '\E3E0',
	caret-down: '\E3F0',
	caret-up: '\E400',
	sorting: '\E40A',
	sorting-ascending: '\E40B',
	sorting-descending: '\E40C',
	search: '\E410',
	zoom-in: '\E420',
	zoom-out: '\E430',
	zoom-reset: '\E440',
	refresh: '\E441',
	redo: '\E442',
	undo: '\E443',
	unlock: '\E444',
	toggle-off: '\E445',
	toggle-on: '\E446',
	announcements: '\E450',
	announcements-active: '\E451',
	cloud-analytics: '\E460',
	code: '\E470',
	complete-visit: '\E471',
	expected-visit: '\E472',
	missed-visit: '\E473',
	partial-visit: '\E474',
	not-available: '\E475',
	external-link: '\E480',
	pill: '\E481',
	organization: '\E482',
	imaging: '\E483',
	cardiac: '\E484',
	ecoa: '\E485',
	respiratory: '\E486',
	bp-overread: '\E487',
	etm: '\E488',
	data-insights: '\E489',
	epx: '\E490',
	admin: '\E491',
	data-cleaning: '\E492',
	ert-global-account: '\E493',
	ert-learn: '\E494',
	expert: '\E495',
	vacation-mode: '\E496',
	patient-profile: '\E497',
	spiro-support: '\E498',
	gather: '\E499',
	placeholder-app: '\E500',
	lock: '\E501',
	user-plus: '\E502',
	precision-motion: '\E503',
	
)

