@import '../colours';

@mixin pulse-circle($pulse-shadow-color) {
	@keyframes pulse-circle {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba($pulse-shadow-color, 1);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 6px rgba($pulse-shadow-color, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba($pulse-shadow-color, 0);
		}
	}
}

@include pulse-circle($vv-red-video);

.live-indicator {
	border-radius: 50%;
	height: 10px;
	width: 10px;
	transform: scale(1);
	background: rgba($vv-red-video, 1);
	box-shadow: 0 0 0 0 rgba($vv-red-video, 1);
	animation: pulse-circle 2s infinite;
}
