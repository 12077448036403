@import '../_mixins';

.full-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  .full-modal__content {
    background: url('/assets/web/images/visit-splash-white.png');
    background-repeat: repeat;
  }

  .full-modal__message {
    flex: 1;
  }

  .full-modal__text {
    text-align: center;
    font-size: 17px;

    h4,
    span {
      margin: 5px 0;
    }

    h4 {
      font-size: 19px;
    }
  }

  .full-modal__main-icon {
    width: 12.5rem;
    height: 12.5rem;
    font-size: 12.5rem;
    color: #a11268;
  }

  .full-modal__control-panel {
    padding: 0 25px 25px 25px;

    @include xs {
      padding: 0 7px 7px 7px;
    }

    @include xs {
      padding: 25px 7px 7px 7px;
    }

    button {
      @include vt-button;
      min-width: 130px;
      background-color: white;
    }
  }
}
