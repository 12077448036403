//// ----------------------------------------------------------------------
///
/// Viewport variables
///
/// @group configuration
/// @module sass/configuration/foundation/viewports
///
//// ----------------------------------------------------------------------

/// viewports
//// ----------------------------------------------------------------------

// Breakpoints
$es-breakpoint-mobile-large: 500px;
$es-breakpoint-tablet: 768px;
$es-breakpoint-tablet-large: 900px;
$es-breakpoint-notebook: 1024px;
$es-breakpoint-desktop: 1200px;

// Viewport-Ranges Min/Max

// PLEASE NOTE, WHEN CHANGING VALUES HERE:
// THIS EXAMPLE LEADS TO A REAL BAD ERROR:
// $es-tablet-small-max: $es-breakpoint-tablet -1;
// which will result in "768px -11" instead of the expected "768px" due to the 
// missing space. This is a SASS "feature"

// Mobile first, "min mobile" = 0
$es-mobile-max: $es-breakpoint-mobile-large - 1;
$es-mobile-large-min: $es-breakpoint-mobile-large;
$es-mobile-large-max: $es-breakpoint-tablet - 1;
$es-tablet-min: $es-breakpoint-tablet;
$es-tablet-max: $es-breakpoint-tablet-large - 1;
$es-tablet-large-min: $es-breakpoint-tablet-large;
$es-tablet-large-max: $es-breakpoint-notebook - 1;
$es-notebook-min: $es-breakpoint-notebook;
$es-desktop-min: $es-breakpoint-desktop;

// Viewport-Ranges
// If there are viewport ranges needed, which only select one breakpoint area (like tablet only)
// they should be named tablet-tablet-large-up.
$es-viewport-ranges: (
        'mobile-only':                      (null, $es-mobile-max),
        'mobile-large-up':                  ($es-mobile-large-min, null),
        'mobile-large-down':                (null, $es-mobile-large-max),
        'tablet-up':                        ($es-tablet-min, null),
        'tablet-down':                      (null, $es-tablet-max),
        'tablet-large-up':                  ($es-tablet-large-min, null),
        'notebook-up':                      ($es-notebook-min, null),
        'desktop-up':                       ($es-desktop-min, null),
) !default;