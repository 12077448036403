$vv-blue: #0092bc;
$vv-purple: #a11268;
$vv-blue-dark: #053c66;
$vv-purple-dark: #4b0f41;
$vv-blue-mid: #146389;
$vv-orange: #eb743e;
$vv-green: #46a758;
$vv-purple-light: #a11268;
$vv-grey-dark: #616161;
$vv-red-video: #a92c30;
$vv-green-video: #80b823;
$vv-white: white;
$vv-grey: #ececec;
