// Breakpoints
$screen-xs-max: 575px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1440px;
$screen-hd-min: 1920px;
$screen-tk-min: 2048px;

// Minimum supported viewport width for emulation
$min-viewport-width: 1024px;

// Smaller devices
@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// 2 Extra large devices
@mixin hd {
  @media (min-width: #{$screen-hd-min}) {
    @content;
  }
}

// 2 Extra large devices
@mixin tk {
  @media (min-width: #{$screen-tk-min}) {
    @content;
  }
}

@mixin vt-button {
  /*border-radius: 50px;*/
  width: auto;
  height: 44px;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 7px 2px;
  border-color: #a11268;
  border-width: 2px;
  border-style: solid;

  &.primary,
  &.warn,
  &.mat-button-base.mat-primary,
  &.mat-button-base.mat-warn {
    color: rgb(255, 255, 255);
  }

  &.primary,
  &.mat-button-base.mat-primary {
    background-color: #a11268
  }

  &.warn,
  &.mat-button-base.mat-warn {
    background-color: #e6474d;
    border: none;
  }
}
