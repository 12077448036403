@use '@angular/material' as mat;
@import '@angular/material/theming';

@import './lib/styles/full-modal';
@import './lib/styles/live-indicator';
@import './lib/styles/emulation-error-icon';

@import './lib//styles/material-design-icons';
@import './lib//styles/material-design-icons-outlined';

@include mat.core();

$vv-purple: (
  50: #e0f2f7,
  100: #b3deeb,
  200: #80c9de,
  300: #4db3d0,
  400: #26a2c6,
  500: #a11268,
  600: #008ab6,
  700: #007fad,
  800: #0075a5,
  900: #006397,
  A100: #c2e7ff,
  A200: #8fd2ff,
  A400: #5cbeff,
  A700: #42b4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$vv-palette-primary: mat.define-palette($vv-purple);
$vv-palette-accent: mat.define-palette($vv-purple);

// $vv-theme: mat.define-light-theme($vv-palette-primary, $vv-palette-accent);

$vv-theme: mat.define-light-theme((
 color: (
   primary: $vv-palette-primary,
   accent: $vv-palette-accent
 )
));

@include mat.all-component-themes($vv-theme);

// Material Override imports
@import './lib/styles/mat_form-field';

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, sans-serif;
}
// Custom font

@font-face {
  font-family: 'DINPro';
  src: url('/assets/fonts/DINPro/DINPro.woff') format('woff');
}

$DINPro: 'DINPro', Roboto, sans-serif;

// NEW CLARIO STYLES AND FONTS 
@font-face {
  font-family: 'Callop';
  src: url('/assets/fonts/Callop/Callop-Regular.woff') format('woff');
}

$Callop: 'Callop', Roboto, sans-serif;

// NEW CLARIO STYLE COLOR CODES 
$vv-clario-purple: #5E1347;
$vv-clario-grey: #E6E8EB;
$vv-clario-white: #FFFFFF;
$vv-clario-blue-link: #006ADC;
$vv-clario-black: #000000;
$vv-clario-gray-black: #11181C;
$vv-clario-gray-12: #11181C;
$vv-clario-gray-9: #889096;
$vv-clario-gray-11: #687076;

//Colours

$vv-blue: #0092bc;
$vv-purple: #a11268;
$vv-blue-mid: #146389;
$vv-blue-dark: #053c66;
$vv-purple-dark: #4b0f41;
$vv-yellow: #f7c53f;
$vv-green: #46a758;
$vv-purple-light: #a11268;
$vv-grey-dark: #616161;
$vv-red-video: #b92d12;
$vv-green-video: #80b823;

// Breakpoints
$screen-xs-max: 575px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1440px;
$screen-hd-min: 1920px;
$screen-tk-min: 2048px;

// Smaller devices
@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// 2 Extra large devices
@mixin hd {
  @media (min-width: #{$screen-hd-min}) {
    @content;
  }
}

// 2 Extra large devices
@mixin tk {
  @media (min-width: #{$screen-tk-min}) {
    @content;
  }
}

// NEW CLARIO STYLES START 

.es-timer-popup-layout {
  .es-visit-timer-column {
    .es-card-header {
      .timer-icon {
        width: 64px;
        height: 64px;
        margin: 0 auto;
        background: url(/assets/web/images/timer-icon.svg) no-repeat;
      }
      h2 {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
    }
    .es-timer-popup-footer {
      margin-top: 30px;
    }
  }
}

.es-device-restriction-popup-layout {
  .es-visit-mobile-restriction-column {
    .es-card-header {
      .warning-icon {
        width: 64px;
        height: 64px;
        margin: 0 auto;
        background: url(/assets/web/images/warning-icon.svg) no-repeat;
      }
      h2 {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        white-space: nowrap;
      }
    }
    .es-timer-popup-footer {
      margin-top: 30px;
    }
  }
}

// welcome screen start -----
.es-welcome-layout {
  .es-welcome-panel {
    padding: 32px 22px 32px 32px;
    background: #FFFFFF;
    border: 1px solid #E6E8EB;
    box-shadow: 0px 4px 8px -5px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    .es-text-group {
      overflow: hidden;
      h2 {
        font-weight: 600;
      }
      p {
        font-size: 18px;
        padding-top: 24px;
        margin: 0;
      }
      p.es-setting-up {
        font-size: 16px;
        padding: 24px 0 12px 0;
        text-align: center;
      }
      .progress-bar {
        position: relative;
        height: 10px;
        width: 200px;
        background: white;
        background-color: #D9D9D9;
        margin: 0 auto;
        border-radius: 10px;
      }
      
      .progress-fill {
        position: absolute;
        height: 10px;
        width: 0%;
        animation: progress-forward 3s infinite;
        background: #0E8574;
        border-radius: 10px;
      }
  
      @keyframes progress-forward {
        0% {
          width: 0%;
        }
        25% {
          width: 25%;
        }
        50% {
          width: 50%;
        }
        75% {
          width: 75%;
        }
        85% {
          width: 85%;
        }
        95% {
          width: 95%;
        }
        100% {
          width: 100%;
        }
      };     
    }
  }
}
// welcome end -----


// NEW CLARIO CHECKING CODE STYLE START
.es-custom-layout {
  .es-custom-panel {
    .es-text-group {
      overflow: hidden;
      h2 {
        font-weight: 600;
      }
      p {
        font-size: 18px;
        padding-top: 14px;
        margin: 0;
        font-weight: 500;
      }
      .progress-bar {
        position: relative;
        height: 10px;
        width: 200px;
        background: white;
        background-color: #D9D9D9;
        margin: 0 auto;
        border-radius: 10px;
        margin-top: 24px;
      }

      .progress-fill {
        position: absolute;
        height: 10px;
        width: 0%;
        animation: progress-forward 3s infinite;
        background: #0E8574;
        border-radius: 10px;
      }

      @keyframes progress-forward {
        0% {
          width: 0%;
        }
        25% {
          width: 25%;
        }
        50% {
          width: 50%;
        }
        75% {
          width: 75%;
        }
        85% {
          width: 85%;
        }
        95% {
          width: 95%;
        }
        100% {
          width: 100%;
        }
      };
    }
  }
}
// NEW CLARIO CHECKING CODE STYLE END


// NEW CLARIO CHECKING CODE STYLE START
.es-custom-layout {
  .es-custom-panel {
    .es-text-group {
      overflow: hidden;
      .es-disclaimer-box {
        padding: 8px 16px;
        background: #FBFCFD;
        border: 2px solid #D7DBDF;
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #11181C;
        .es-disclaimer-text{
          font-size: 16px;
          color: #000;
          margin: 0 0 10px 0;
          font-weight: 600;
        }
        .es-toolbar{
          padding-top: 10px;
        }
        .terms-content {
          p {
            font-size: 14px;
          }
          ul {
            font-weight: 500;
            font-size: 14px;
          }
          max-height: 200px;
          overflow-y: scroll;
          padding-bottom: 30px;
          box-sizing: border-box;
        }
      }
      h2.looks-good-text {
        font-weight: 600;
        text-align: center;
      }
      .vv-icon {
        width: 128px;
        height: 128px;
        background: #F1F3F5;
        border-radius: 50%;
        margin: 0 auto;
        padding: 28px;
        
        .vv-icon__success {
          height: 64px;
          width: 56px;
          margin: 0 auto;
          background: url('/assets/web/images/success-icon.svg') 0 no-repeat;
        }
      }
      
    }
  }
}
// NEW CLARIO CHECKING CODE STYLE END


// NEW CLARIO LOGIN SCREEN STYLE START
.es-gateway {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .es-gateway__bg { 
    img {
      width: 100%;
      height: 100vh;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      z-index: 0;
    }
  }
  .es-gateway-header {
    // background-color: #5E1347;
    border-bottom: solid 1px #e5e5e5;
    height: 72px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 900;
    padding: 0 !important;
    .header-inner {
      position: relative;
      height: 72px;
    }
    .product-name {
      font-family: 'Callop', Roboto, sans-serif;
      font-size: 20px;
      display: flex;
      letter-spacing: 0.1px;
      color: #ffffff;
      padding: 26px 0px;
      float: left;
    }
    .ellipses-1 {
      position: absolute;
      width: 1759.2px;
      height: 681px;
      left: -1280.8px;
      top: -430px;
      border-radius: 50%;
      z-index: -1;
      background: linear-gradient(180deg, #4B0F41 60.21%, rgba(107, 7, 67, 0) 74%);

    }
    .ellipses-2 {
      position: absolute;
      width: 2011.2px;
      height: 681px;
      left: -1018px;
      top: -430px;
      border-radius: 50%;
      background: linear-gradient(180deg, #83285e 70%, rgba(66, 15, 46, 0) 74%);
      z-index: -2;
    }
    .ellipses-3 {
      position: absolute;
      width: 3513px;
      height: 600px;
      left: -1490px;
      top: -529px;
      background: #7D2855;
      z-index: -3; 
    }
    .es-logo {
      display: block !important;
      padding: 21px 32px;
      line-height: 0 !important;
      float: left;
      img {
        height: 16px;
        width: 111px;
      }
    }
    
    .es-study-lang-select {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
    }
  }
  .es-lang-select.siteUser {
    top: 0;
  }
  .es-lang-select.subject {
    top: 50px;
  }
  .es-lang-select.participant {
    display: flex !important;
    justify-content: space-between !important;
  }
  .es-lang-select {
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 0;
    padding: 16px;
    right: 0;
    display: flex;
    // justify-content: flex-end;
    // .es-select:after {
    //   padding: 1.5rem 2rem;
    // }
    // .es-select {
    //   select {
    //     padding: 1.5rem 4.3rem 1.5rem 2rem;
    //     font-size: 14px;
    //   }
    // }
  }
  .es-gateway-body {
    width: 100%;
    z-index: 1;

    // create account
    .es-gateway-column.-es-flex-horizontal.es-create-account-layout {
      margin-top: 130px;
    }
    // security screen
    .es-gateway-column.-es-flex-horizontal.es-secirity-que-layout {
      display: block;
      margin: 0 auto;
      margin-top: 140px;
      .es-toolbar.es-secuirty-toolbar {
        padding: 10px;
      }
    }

    .es-gateway-column.-es-flex-horizontal {
      justify-content: center;
      .es-column.es-security-column {
        float: none;
        margin: 0 auto;
      }
      .es-column {
        @include md {
          width: 589px;
        }
      }
      .es-column.es-invite-code-column {
        float: none;
        margin: 0 auto;
        h2.es-short-code {
          text-align: center;
          letter-spacing: 8px;
          font-size: 24px;
        }
      }
      .es-column.es-visit-timer-column {
        @include md {
          width: 694px;
        }
      }
      .es-column.es-disclaimer-column {
        @include md {
          width: 713px;
        }
        .es-toolbar {
          padding: 10px 0 0;
        }
      }
      .es-column.es-visit-assessment-form {
        @include md {
          width: 710px;
        }
        h2 {
          font-size: 24px;
        }
        
        .es-panel.es-custom-panel{
          padding: 24px;

          p.waiting-text {
            margin: 20px 0 0 5px;
            font-size: 16px;
            font-weight: 500;
          }
          .es-notification p {
            font-size: 14px;
            font-weight: 400;
            padding: 0;
          }
          .es-loading-indicator.-es-visible {
            display: table-caption;
            margin-top: 20px;
            margin-left: 10px;
          }
          .custom-toolbar.es-toolbar {
            padding-top: 0px;
          }
          .es-toolbar {
            padding-top: 20px;
          }
          
        }
      }
      h2 {
        font-weight: 600;
      }
      .es-panel.es-custom-panel.security-que-panel {
        .security-que-ans-form {
          padding: 10px;
          max-height: calc(100vh - 380px);
          overflow-y: auto;
        }
      }
      .es-panel.es-custom-panel {
        padding: 32px;
        .es-custom-info {
          background: #E1F0FF;
          border: 1px solid #5EB0EF;
          border-radius: 4px;
          display: flex;
          padding: 8px;
          .info-icon {
            padding: 14px;
            width: 20px;
            height: 21px;
            margin-top: 3px;
            background: url(/assets/web/images/info-icon.svg) no-repeat;
          }
          .info-text {
            float: left;
            width: 100%;
            p {
              color: #00254D;
              margin: 0 auto;
            }
          }
        }
        .es-toolbar {
          .es-toolbar-item.es-custom-toolbar-item {
            margin-right: 24px;
          }
          .es-toolbar-item.build-version {
            line-height: 30px;
            color: #687076;
          }
        }
      } 
      .es-form-field {
        .error-container {
          width: 100%;
          display: block;
          height: 24px;
        }
        .error-icon {
          height: 16px;
          width: 16px;
          float: left;
          margin-right: 5px;
          margin-top: 2px;
          background: url('/assets/web/images/error-message-icon.svg'); 
        }
        
      }
    }
    .es-gateway-column.-es-flex-horizontal {
      justify-content: center;
      .es-content-box {
        .es-toolbar {
          a {
            width: auto;
            color: #006ADC;
          }
        }
      }
    }
  }

  .es-gateway-body.participant {
    max-width: 100%;
    padding: 20px;
    margin-top: 60px;
  }

  .es-gateway-body.provisioner {
    margin-top: 40px;
  }

  .es-custom-visit-btn {
    button {
      display: flex;
      align-items: center;
      .callIcons {
        height: 20px;
        width: 20px;
        float: left;
        margin-right: 5px;
        margin-top: 2px;
        
      }
      .copy_url_icon {
        background: url(/assets/web/images/copy-icon.svg) no-repeat;
        height: 20px;
        width: 20px;
      }
      .transfer_icon {
        background: url(/assets/web/images/transfer-icon.svg) no-repeat;
        height: 20px;
        width: 25px;
        margin-right: 0;
      }
      .call_end_icon {
        background: url(/assets/web/images/call-white-icon.svg) no-repeat;
        height: 20px;
        width: 25px;
        margin-top: 0;
      }
      .request_control {
        background: url(/assets/web/images/request-control-icon.svg) no-repeat;
        height: 18px;
        width: 20px;
        margin-left: 5px;
      }
      .mat-icon {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }
  }
}
.es-panel {
  .es-no-participant {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
    height: calc(100vh - 115px);
    .es-icon-text-container {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      justify-content: center;
      width: 100%;
      .es-participant-icon {
        height: 120px;
        width: 120px;
        background: #F8F9FA;
        background: url('/assets/web/images/participant-icon.svg'); 
      }
      .es-no-participant-text {
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        line-height: 24px;
        padding: 24px;
        color: #11181C;
      }
      .es-no-participant-sub-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #11181C;
        display: flex;
      }
    }
    .es-content__btn-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0px;
      button {
        display: flex;
      }
    }
  }
}

.es-gateway.participant {
  align-items: flex-start;
}
.cdk-overlay-pane.es-custom-dialog-panel{
  .mat-dialog-container{
    padding:0;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}
// NEW CLARIO LOGIN SCREEN STYLE END

// NEW CLARIO FORGOT PASSWORD SCREEN STYLE START
.reset-password-layout {
  .es-panel.panel-padded {
    padding: 27px;
  }

  .es-content-box {
    .error-icon {
      height: 16px;
      width: 16px;
      min-width: 16px;
      float: left;
      margin-right: 5px;
      margin-top: 2px;
      background: url('/assets/web/images/error-message-icon.svg'); 
    }
  }

  .es-inline-message.inline-message-reset-password {
    display: flex;
    margin-bottom: 10px;
    .es-icon-info-circle {
      margin-right: 10px;
    }
    p {
      word-break: normal;
    }
  }

  .es-form {
    form.form-spaced {
      margin-bottom: 10px;
    }
    .es-label.label-security-one-line {
      width: 100%;
    }
  }

  .es-popup.-es-open.popup-reset-password {
    display: flex;
    align-items: center;
    .es-popup-content {
      width: 720px;
      padding: 27px;
    }
  }
}
// NEW CLARIO FORGOT PASSWORD SCREEN STYLE END

// NEW CLARIO STYLES END 

// // responsive columns //
// .vv-row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-left: -1rem;
//   margin-right: -1rem;

//   .vv-col-6 {
//     width: 100%;
//     padding: 1rem;
//     box-sizing: border-box;

//     @include md {
//       max-width: 50%;
//     }
//   }
// }

// alignment //
.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

// ert logo //
.logo-ert {
  width: 100%;
  max-width: 216px;
  height: 73px;

  .ert-white {
    fill: white;
  }

  .ert-blue {
    fill: #003865;
  }

  .ert-green {
    fill: #d1de40;
  }

  .ert-bluel {
    fill: #05abd7;
  }
}

.mat-snack-bar-container {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

// vv styles //
.page-wrapper {
  margin: auto;
  min-height: 100vh;
  background: #f9fafb;
  position: relative;
  overflow-x: hidden;
  box-sizing: border-box;

  &.page-wrapper-login {
    background: white;
  }

  .vv-login__img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;

    @include xs {
      bottom: 60px;
      max-width: none;
      width: 150%;
      transform: translateX(calc(-52%));
    }
  }

  @include sm {
    padding: 24px;
  }

  .page-container {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    .page-header {
      width: 100%;
      display: flex;
      justify-content: center;

      .logo-ert {
        width: 216px;
        margin: 8px 0 32px 0;
        display: none;
        @include sm {
          display: block;
        }
      }

      &.logo-ert__login {
        .logo-ert {
          width: 160px;
          display: block;
          margin: 16px 0 0 0;
        }
      }
    }

    .page-content {
      width: 100%;
      align-items: flex-start;
      font-family: 'Roboto', sans-serif;

      .mat-card {
        padding: 16px;
        position: relative;
        min-height: 220px;

        @include xs {
          border-radius: 0;
          box-shadow: none;
          background: none;
          min-height: calc(100vh - 32px);
          display: flex;
          flex-wrap: wrap;
          flex-flow: column;

          &.vv-notify {
            flex-flow: inherit;
          }

          &.vv-login {
            min-height: calc(100vh - 122px);
          }
        }

        @include sm {
          padding: 24px;
        }

        &.vv-notify {
          display: flex;
          align-items: center;
          justify-content: center;

          .vv-notify__msg {
            text-align: center;
          }
        }

        .vv-icon {
          width: 128px;
          height: 128px;

          .vv-icon__success {
            fill: #80b823;
          }
        }

        .card-title {
          font-size: 30px;
          font-weight: bold;
          color: $vv-purple;
          margin: 0 0 24px 0;

          @include xs {
            align-self: flex-start;
          }
        }

        .card-subtitle {
          font-size: 16px;
          color: #000;
          margin: 0 0 32px 0;

          @include xs {
            align-self: flex-start;
          }
        }

        .mat-card-content {
          @include xs {
            flex: 1 1 100%;
          }
        }

        .mat-card-actions {
          margin: 0;
          align-self: flex-end;
          text-align: right;
          @include xs {
            width: 100%;
          }

          button {
            &.mat-secondary {
              color: $vv-purple;
              background: none;
            }

            &:last-child {
              margin-left: 16px;
            }

            @include xs {
              &:only-child {
                width: 100%;
                margin-left: 0;
              }
            }
          }
        }

        .terms-container {
          position: relative;

          .terms-content {
            max-height: 200px;
            overflow-y: scroll;
            padding-bottom: 30px;
            box-sizing: border-box;

            p:first-child {
              margin-top: 0;
            }

            @include xs {
              padding-right: 20px;
            }

            &::after {
              content: '';
              display: block;
              position: absolute;
              height: 40px;
              background: linear-gradient(
                rgba(255, 255, 255, 0) 10%,
                rgba(255, 255, 255, 1)
              );
              pointer-events: none;
              bottom: 0;
              right: 17px;
              left: 0;

              @include xs {
                top: 160px;
                right: 12px;
                bottom: auto;
                background: linear-gradient(
                  rgba(238, 238, 238, 0) 10%,
                  rgba(238, 238, 238, 1)
                );
              }
            }
          }
        }

        .forgot-password {
          display: block;
          color: $vv-purple;
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
        }

        .build-version {
          display: block;
          color: $vv-purple;
          width: 100%;
          max-width: 400px;
          text-align: right;
        }

        .label-wrapper {
          display: flex;
          color: $vv-purple;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          padding-top: 15px;
        }

        .new-account {
          display: block;
          width: 100%;
          color: $vv-purple;
          font-weight: bold;
          text-align: right;
          margin-top: 24px;
          text-decoration: none;
        }

        &.vv-login {
          max-width: 600px;
          margin: 0 auto;
          z-index: 2;
          overflow: hidden;
          background: none;

          @include xs {
            &::after {
              content: '';
              display: block;
              height: 60px;
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: #e1e9f0;
              backdrop-filter: blur(4px);
              -webkit-backdrop-filter: blur(4px);
              z-index: -1;
            }
          }

          @include sm {
            border: 1px solid $vv-purple;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: rgba(255, 255, 255, 0.6);
              backdrop-filter: blur(4px);
              -webkit-backdrop-filter: blur(4px);
              z-index: -1;
            }
          }

          .card-title {
            font-family: $DINPro;
            font-size: 42px;
            text-align: center;
            margin: 24px 0;
            width: 100%;
            @include xs {
              font-size: 28px;
              margin-top: 0;
            }
          }
          .mat-card-actions {
            button {
              display: block;
              width: 250px;
              margin: 24px auto 0 auto;
              @include xs {
                width: 100%;
              }
            }
          }
        }

        .card-code {
          font-size: 2rem;
          color: $vv-purple-dark;
          margin-bottom: 2rem;
          text-align: center;
          letter-spacing: 6px;
        }

        &.vv-error {
          border-radius: 0;
          box-shadow: none;
          background: none;
          min-height: 0;
          display: flex;
          flex-wrap: wrap;
          flex-flow: column;

          .card-title {
            margin-top: 96px;
            text-align: center;
            align-self: center;

            @include xs {
              margin-top: 24px;
            }
          }

          .card-subtitle {
            margin-bottom: 0;
            text-align: center;
            color: $vv-grey-dark;
          }

          img {
            max-width: 100%;
            width: 600px;
            height: auto;
            margin: 0 auto;
          }
        }
      }
    }
  }

  // Form Styles //

  .form {
    //max-width: 400px;
    width: 100%;
    //margin: 0 auto;

    .mat-form-field {
      display: block;
      max-width: 400px;
      margin: 0 auto;

      .mat-input-element {
        font-size: 16px;
      }
    }

    .full-width {
      width: 100%;
    }

    .form-title {
      padding-top: 32px;
    }

    &.vv-radio {
      .vv-radio__wrapper {
        display: flex;

        @include sm {
          max-width: 400px;
          margin: 0 auto;
        }

        .mat-radio-button {
          display: flex;
          align-items: center;
          margin-right: 8px;
        }

        .mat-form-field {
          display: inline-block;
          max-width: none;
        }
      }
    }
  }

  // SPLASH PAGE STYLES //

  &.vv-splash {
    background: linear-gradient(#15658b, #053c67);
    display: flex;

    .page-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .page-header {
        display: none;
      }

      .logo-ert {
        display: block;
        margin: 0 auto 32px auto;
        width: 160px;
        height: 54px;
      }

      .splash-title {
        font-size: 40px;
        width: 100%;
        text-align: center;
        font-weight: 300;
        color: #84bd23;
        margin: 0 0 10px 0;
      }

      .splash-subtitle {
        font-size: 20px;
        width: 100%;
        text-align: center;
        color: #86a7bb;
        margin: 0 0 32px 0;
      }

      .mat-card {
        background: none;
        box-shadow: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .mat-card-content {
          width: 100%;
        }

        .mat-card-actions {
          width: 100%;
        }

        .form {
          margin-bottom: 32px;
        }

        .forgot-password {
          color: #cfdce4;
        }

        .new-account {
          display: block;
          width: 100%;
          color: $vv-purple;
          font-weight: bold;
          text-align: right;
          margin-top: 24px;
          text-decoration: none;
        }
      }
    }
  }

  // Dashboard

  .vv-dash__container {
    mat-drawer-content {
      min-height: 100vh;
    }

    min-height: 100vh;

    .vv-dash__profile {
      background: #fff;
      border-bottom: 1px solid #d7d7d7;
      display: flex;
      align-items: center;
      .mat-card-avatar {
        margin: 1.5rem 0 1.5rem 1.5rem;
      }

      .mat-form-field {
        margin: 0 1.5rem;
        color: white;
      }

      ::ng-deep
        .mat-form-field-type-mat-native-select
        .mat-form-field-infix::after {
        color: white;
      }

      ::ng-deep .mat-form-field-underline {
        display: none;
      }
    }

    .vv-dash__nav {
      .active-list-item {
        color: $vv-purple;

        .mat-list-icon {
          color: $vv-purple;
        }
      }

      .mat-list-icon {
        color: $vv-grey-dark;
      }
    }

    .vv-toolbar {
      background: $vv-purple;

      @include xs {
        padding: 0 6px;
      }

      button {
        padding: 0;
        min-width: 36px;

        mat-icon {
          &.menu {
            color: white;
          }

          &.add {
            color: #fff;
          }
        }
      }

      .vv-toolbar__title {
        text-align: center;
        color: white;
        min-width: 108px;
        display: flex;
        @include xs {
          font-size: 1rem;
          flex-grow: 1;
        }
      }

      .vv-toolbar__logo {
        @include xs {
          opacity: 0;
        }
      }

      .logo-ert {
        width: 100%;
        max-width: 80px;
        max-height: 30px;
      }

      // SEARCH STYLES BEGIN //

      .vv-search {
        position: absolute;
        right: 60px;
        display: flex;
        height: 40px;
        z-index: 2;
        overflow: hidden;
        width: 40px;
        max-width: 400px;
        transition: width 0.3s;

        @include xs {
          right: 40px;
        }

        form {
          position: absolute;
          width: 40px;

          .mat-form-field {
            width: 400px;

            @media only screen and (max-width: 575px) {
              max-width: none;
            }

            @media only screen and (min-width: 880px) and (max-width: 1023px) {
              max-width: 600px;
            }

            @media only screen and (min-width: 1024px) and (max-width: 1366px) {
              max-width: 300px;
            }
          }

          .mat-input-element {
            font-size: 14px;
            background: white;
            line-height: 40px;
            padding-left: 45px;
            width: 100%;
            opacity: 0;
            transition: opacity 0.3s;

            @include xs {
              width: calc(100% - 45px);
            }
          }
        }

        mat-icon {
          &.search {
            color: white;
            transition: color 0.2s;
          }
        }

        @include xs {
          left: auto;
          transition: left 0.3s;
        }

        &.__open {
          width: 100%;
          max-width: 400px;
          position: absolute;
          right: 60px;

          @include xs {
            left: 10px;
            width: auto;
            right: 46px;
          }

          @media only screen and (max-width: 575px) {
            max-width: none;
          }

          @media only screen and (min-width: 880px) and (max-width: 1023px) {
            max-width: 600px;
          }

          @media only screen and (min-width: 1024px) and (max-width: 1366px) {
            max-width: 300px;
            width: 300px;
          }

          form {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;

            .mat-form-field {
              width: 400px;

              @media only screen and (min-width: 880px) and (max-width: 1190px) {
                width: 600px;
              }

              @media only screen and (min-width: 1024px) and (max-width: 1366px) {
                max-width: 300px;
              }

              @include xs {
                width: 100%;
              }
            }

            .mat-input-element {
              opacity: 1;
            }
          }

          mat-icon {
            &.search {
              color: $vv-purple-dark;
            }
          }
        }
      }

      // SEARCH STYLES END //
    }

    .vv-table__card {
      margin: 24px;
      padding: 0;
      position: static;

      @include xs {
        margin: 0;
      }

      .vv-table {
        width: 100%;

        .mat-column-status {
          position: relative;

          .mat-icon {
            position: absolute;
            top: 14px;
            font-size: 1rem;
            width: 1rem;
            height: 1rem;
          }

          span {
            padding-left: 32px;

            @include xs {
              padding-left: 18px;
            }
          }
        }

        .mat-column-actions {
          position: relative;

          @include xs {
            position: static;
          }

          .mat-icon {
            color: $vv-grey-dark;
          }

          .vv-actions__nav {
            transform: translateX(100%);
            position: relative;
            right: 160px;
            width: 100%;
          }

          @include xs {
            .vv-actions__nav {
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: #fafafa;
              z-index: 3;
              transition: transform 0.2s;

              .vv-actions__back {
                width: 100%;
                background: $vv-purple-dark;
                height: 56px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .mat-icon-button {
                  width: 40px;
                  height: 40px;
                  text-align: center;
                  background: none;
                  border-radius: 50%;
                  padding-left: 0;
                  .mat-icon {
                    color: white;
                  }
                }
              }
            }

            .__open {
              .vv-actions__nav {
                transform: translateX(0);
              }
            }
          }

          .vv-actions__mob,
          .vv-actions__number {
            display: none;

            @include xs {
              display: inline-block;
            }
          }

          .vv-actions__number {
            color: white;
            font-size: 16px;
          }

          .mat-icon-button {
            .mat-icon-button-text {
              display: none;
            }

            @include xs {
              width: 100%;
              height: 48px;
              text-align: left;
              padding-left: 16px;
              background: white;
              border-radius: 0;

              .mat-icon-button-text {
                display: inline-block;
                padding-left: 8px;
              }
            }
          }
        }

        .mat-cell {
          font-size: 1rem;

          @media only screen and (max-width: 620px) {
            font-size: 0.875rem;
          }

          .mat-button {
            padding: 0 12px;
            min-width: 24px;
          }
        }

        td:first-of-type,
        th:first-of-type {
          @include xs {
            padding-left: 16px;
          }
        }

        td:nth-of-type(2),
        th:nth-of-type(2) {
          padding-left: 24px;

          @include xs {
            padding-left: 16px;
          }
        }

        th.mat-header-cell {
          color: black;
          font-weight: bold;
        }

        th.mat-header-cell:last-of-type,
        td.mat-cell:last-of-type,
        td.mat-footer-cell:last-of-type {
          width: 140px;
          @include xs {
            width: 50px;
            padding-right: 0;
          }
        }

        th.mat-header-cell:last-of-type {
          span {
            padding-left: 10px;

            @include xs {
              display: none;
            }
          }
        }
      }
    }

    .vv-dash__notify {
      padding: 0 24px;
      flex-grow: 1;

      h1 {
        margin-bottom: 0;
        color: $vv-purple-dark;
        font-size: 1.5rem;
      }

      p {
        color: $vv-grey-dark;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .vv-img__nocalls {
        width: 600px;
        height: auto;
        max-width: 100%;
      }

      .mat-icon {
        &.add {
          color: $vv-green;
        }
      }
    }
  }
}

//Video Call

.vv-video__wrapper {
  background: $vv-purple-dark;
  height: 100vh;

  .vv-video__container {
    width: 100%;
    height: 100vh;
    max-width: 320px;
    max-height: 600px;
    text-align: center;
    color: white;

    h1 {
      margin-bottom: 0;
      color: white;
      font-size: 1.25rem;
      font-weight: normal;
    }

    .vv-video__title {
      margin-top: 2rem;
    }

    .vv-video__content {
      margin-bottom: 48px;

      p {
        margin-bottom: 24px;
        font-size: 14px;

        &.vv-video__notify {
          color: $vv-purple-light;
        }
      }
    }

    .vv-video__actions {
      button {
        margin: 0.75rem;
      }

      .vv-video__calling {
        font-size: 0.75rem;

        .vv-video__call {
          background: $vv-green-video;
        }

        .vv-video__end {
          background: $vv-red-video;
        }
      }
    }
  }
}

//Dialog
.mat-dialog-title {
  text-align: center;
  margin: 0 0 0 0;
  font-weight: bold;
}
.mat-dialog-content {
  text-align: center;
  max-width: 500px;
  p {
    margin-top: 0;
    color: $vv-grey-dark;
  }
}

.mat-dialog-actions {
  button {
    &.mat-secondary {
      color: $vv-purple;
      background-color: white;
      border: 1px solid transparent;
      border-color: $vv-purple;
    }

    &:last-child {
      margin-left: 16px;
    }

    @include xs {
      &:only-child {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
.timer-icon.mat-icon svg {
  fill: #a11268
}

/**
 * ==============================================
 * Dot Pulse - https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 */

.dot-pulse-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4px;
  margin-top: 3rem;
}

.dot-pulse-label {
  color: $vv-purple;
  margin-bottom: 10px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $vv-purple;
  color: $vv-purple;
  box-shadow: 9999px 0 0 -5px $vv-purple;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $vv-purple;
  color: $vv-purple;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px $vv-purple;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px $vv-purple;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px $vv-purple;
  }
  30% {
    box-shadow: 9984px 0 0 2px $vv-purple;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px $vv-purple;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px $vv-purple;
  }
  30% {
    box-shadow: 9999px 0 0 2px $vv-purple;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px $vv-purple;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px $vv-purple;
  }
  30% {
    box-shadow: 10014px 0 0 2px $vv-purple;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px$vv-purple;
  }
}

// Scrollbar
@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 20%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 0 $size $foreground-color;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: rgba(#454444, 0.2);
  }
}

// General pages' layout
.visit-end-container .icon,
.platform-error-container .icon,
.visit-error-container .icon,
.viewport-error-container .icon {
  color: #a11268;
  font-size: 130px;
  width: 130px;
  height: 130px;
  user-select: none;
}

// .visit-error-container .mat-icon {
//   color: white;
// }

// .visit-error-container,
// .visit-wait-container,
// .visit-end-container,
// .platform-error-container .content,
// .viewport-error-container {
//   background: url('/assets/web/images/visit-splash-white.png');
//   background-repeat: repeat;
// }

.platform-error-container .text,
.visit-end-container .text,
.join-visit-container .text,
.visit-error-container .text,
.visit-wait-container .text,
.standalone-visit-container .text,
.viewport-layout-container .text {
  font-weight: 700;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 25px;
  text-align: center;
  color: #000;
  line-height: normal;
}

.standalone-layout-container .header,
.platform-error-container .header,
.session-timeout-container .header,
.full-modal-container .full-modal__header {
  height: 65px;
  min-height: 65px;
  margin: 0 15px;
  flex: 0 1 auto;
}

.standalone-layout-container .content,
.platform-error-container .content,
.session-timeout-container .content,
.full-modal-container .full-modal__content {
  flex: 1;
}

.standalone-layout-container .logo,
.platform-error-container .logo,
.session-timeout-container .logo,
.full-modal-container .logo {
  height: 40px;
  width: 40px;
}

// .standalone-layout-container .visit-wait-container,
// .platform-error-container .visit-wait-container,
// .visit-end-container,
// .visit-error-container,
// .platform-error-container .content,
// .viewport-error-container,
// .session-timeout-container .content,
// .full-modal-container .full-modal__content {
//   $border-radius: 16px;
//   border-radius: $border-radius $border-radius 0 0;
//   box-shadow: 0px 1px 7px 2px rgba(0, 0, 0, 0.24);
// }

.visit-wait-container,
.visit-error-container,
.visit-end-container,
.viewport-error-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.platform-error-container,
.standalone-layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}

.platform-error-container {
  .es-panel.es-custom-panel {
    padding: 30px;
  }
}

// global spinner
.loader {
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;

  &.loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: inline-block;
    width: 90px;
    height: 90px;
    border: 11px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #a11268;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}

.mat-toolbar {
  font: 500 20px/13px Roboto, 'Helvetica Neue', sans-serif;
}

.session-timeout-dialog-panel {
  background: #fbfcfd;
  .session-timeout-container {
    .es-column.es-visit-timer-column {
      @include md {
        width: 694px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 125px);
      }
      .es-content-box {
        width: 100%;
      }
    }
  }
  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}

.es-custom-dialog-panel {
  .es-popup.-es-open.-es-modal {
      display: flex;
      background-color: rgba(152,153,154,.6);
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      overflow-x: hidden;
      overflow-y: auto;
      justify-content: center;
      align-items: center;
    .es-popup-content{
      width: 590px;
      padding: 32px;
      .es-popup-header{
        padding: 0 0 22px;
        .header-icon {
          height: 32px;
          width: 32px;
          float: left;
          margin-right: 10px;
        }
        .warningIcon{
          background: url('/assets/web/images/warning-icon.svg') no-repeat;
        }
        .successIcon{
          background: url('/assets/web/images/success-header-icon.svg') no-repeat;
        }
        .warningYellowIcon{
          background: url('/assets/web/images/warning-yellow-icon.svg') no-repeat;
        }
        h2 {
          font-weight: 600;
        }
      }

      .es-form-field {
        .error-container {
          width: 100%;
          display: block;
          height: 24px;
        }
        .error-icon {
          height: 16px;
          width: 16px;
          float: left;
          margin-right: 5px;
          margin-top: 2px;
          background: url('/assets/web/images/error-message-icon.svg'); 
        }
        
      }
    }
  }
  
}
.custom-snackbar {
  background: transparent;
}

snack-bar-container.mat-snack-bar-container.custom-snackbar {
  padding: 0;
  min-height: 0;
}

.language-select-dropdown {
  max-width: 200px;
}

// START :: Handle Below styles for RTL
html[dir=rtl] {
  .es-right {
    float: left !important;
  }
  .es-left {
    float: right !important;
  }
  .-es-invalid {
    .error-icon {
      float: right !important;
      margin-left: 10px;
    }
  }
  .product-name {
    padding: 26px !important;
  }
  .subject-login-form {
    .es-right.subject-form-version {
      float: left !important;
    }
  }
  .select-study-site-form {
    .es-right.study-site-btn {
      float: left;
    }
  }

  .reselect-study-site-button {
    margin-left: 220px;
  }

  .es-table th {
    text-align: right;
  }

  .es-toolbar ul {
    float: right;
  }

  .top-right {
    position:absolute;
    left:25px;
    right: auto;
  }

  .switch-button {
    margin-right: 0px;
  }

  .send-invite-button {
    margin-right: 25px;
  }

  .end-visit-button {
    position: fixed;
    right: 20px;
    left: auto;
  }

  .transfer-control-button {
    left:20px;
    right: auto;
  }
  #cancel-btn.close-icon {
    float: left !important;
  }
  .action-buttons {
    float: left !important;
    padding-left: 30px;
  }
  .status-wrap {
    > span {
      padding-right: 1em;
    }
  }
  .es-icon,
  .es-icon.es-icon-email,
  .es-icon.es-icon-undo,
  .es-icon.es-icon-file-text,
  .callIcons {
    margin-left: 5px;
  }
  .es-icon.es-icon-info-circle {
    margin-left: 10px;
    margin-right: 0;
  }
  .es-custom-info .info-icon {
    margin-left: 5px;
  }
  .visit-call-local-preview-container {
    left: 25px !important;
  }
  .emulation-device-container .control-panel {
    left: 20px !important;
    right: unset;
  }
  .product-option-checked-container {
    box-shadow: inset -5px 0px 0px #7D2855;
  }
  .product-option-container {
    justify-content: end;
    span.product-option {
      width: 100%;
    }
    .visit-option-checkbox {
      float: right;
    }
    .product-item {
      padding-right: 1em;
      padding-left: un
    }
  }
  .session-timeout-container {
    .es-visit-timer-column {
      .es-card-header {
        h2 {
          justify-content: end;
        }
      }
    }
  }
}
// END :: Handle Below styles for RTL