//// ----------------------------------------------------------------------
///
/// variables for sidebar
///
/// @group configuration
/// @module sass/configuration/organisms/sidebar
///
//// ----------------------------1------------------------------------------

$es-sidebar-z-index-mobile: $es-z-index-topmost - 10;
$es-sidebar-z-index-tablet: $es-page-header-z-index - 1;
$es-sidebar-width-mobile: 80vw;
$es-sidebar-width-tablet: 350px;
$es-sidebar-width-desktop: 350px;
$es-sidebar-width-closed: $es-page-header-height + 5px;
$es-sidebar-toggle-width-mobile: $es-page-header-height;
$es-sidebar-toggle-height-mobile: $es-page-header-height;

//Colors
$es-sidebar-background-color: es-color("mono", "white");
$es-sidebar-help-background-color: es-color("mono", "white");
$es-sidebar-toggle-background-color: es-color("mono", "white");

